import React, { useEffect, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { navigate } from 'gatsby'
import MarketingLayout from 'src/components/layout/MarketingLayout'
import { Context as ThemeContext } from 'src/context/ThemeContext'
import Glitch from 'src/components/atoms/Glitch'
import { LightButton } from 'src/components/buttons/Primary'
import { Context as ProfileContext } from 'src/context/ProfileContext'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'
import registerStudentImg  from 'src/images/register/register-student.jpg'
import registerTeacherImg  from 'src/images/register/register-teacher.jpg'

export default function Register(props) {
    const { t } = useTranslation();
    const [animateStudent, setAnimateStudent] = useState(false);
    const [animateTeacher, setAnimateTeacher] = useState(false);
    const { setTheme } = useContext(ThemeContext);
    const { registerStudent, registerTeacher } = useLocalizedRoutes();
    const { state: { profile }, toggleLogin } = useContext(ProfileContext);

    useEffect(() => setTheme('inverse'), []);
    useEffect(() => {
        if (profile) navigate('/')
    }, [profile])

    return (
        <MarketingLayout {...props} title={t('register.title')}>
            <div className="container mx-auto mt-8 xl:mt-24 mb-8 xl:mb-16">
                <div className="flex flex-wrap items-stretch xl:-mx-4">
                    <div className="w-full md:w-6/12 p-4">
                        <div className="relative h-full">
                            <div className="relative z-10 py-8 px-6 lg:py-24 lg:px-12 xl:px-24 xl:py-40 text-center">
                                <h2 className="text-white text-4xl lg:text-5xl font-heading font-bold">{t('register.student.title')} <span className="text-egg-shell-600">{t('register.student.titleHighlight')}</span></h2>
                                <p className="text-white mt-4 lg:mt-8">{t('register.student.intro')}</p>
                                <div className="my-6 lg:my-10">
                                    <LightButton
                                        onClick={() => navigate(registerStudent)}
                                        onMouseOver={() => setAnimateStudent(true)}
                                        onMouseOut={() => setAnimateStudent(false)}
                                    >{t('register.student.button')}</LightButton>
                                </div>
                                <span
                                    onClick={toggleLogin}
                                    className="text-white text-base underline cursor-pointer"
                                >{t('general.signIn')}</span>
                            </div>
                            <div className="absolute inset-0">
                                <Glitch
                                    type={'controlled'}
                                    active={animateStudent}
                                    image={registerStudentImg}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="w-full md:w-6/12 p-4">
                        <div className="relative h-full">
                            <div className="relative z-10 py-8 px-6 lg:py-24 lg:px-12 xl:px-24 xl:py-40 text-center">
                                <h2 className="text-white text-4xl lg:text-5xl font-heading font-bold">{t('register.teacher.title')} <span className="text-egg-shell-600">{t('register.teacher.titleHighlight')}</span></h2>
                                <p className="text-white mt-4 lg:mt-8">{t('register.teacher.intro')}</p>
                                <div className="my-6 lg:my-10">
                                    <LightButton
                                        onClick={() => navigate(registerTeacher)}
                                        onMouseOver={() => setAnimateTeacher(true)}
                                        onMouseOut={() => setAnimateTeacher(false)}
                                    >{t('register.teacher.button')}</LightButton>
                                </div>
                                <span
                                    onClick={toggleLogin}
                                    className="text-white text-base underline cursor-pointer"
                                >{t('general.signIn')}</span>
                            </div>
                            <div className="absolute inset-0">
                                <Glitch
                                    type={'controlled'}
                                    active={animateTeacher}
                                    image={registerTeacherImg}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MarketingLayout>
    );
}
